import { ClockIcon } from '@heroicons/react/outline';
import { Footprints, ShuffleIcon, TrainIcon } from 'lucide-react';
import Image from 'next/image';
import { useLocale, useTranslations } from 'next-intl';

import {
  formatLinkDate,
  getDiffHoursFromDates,
  getHoursFromDuration,
} from 'utils/datesUtils';
import { Leg } from 'utils/journey';
import { LineImages as Line } from 'utils/OperatorsAndLinesLiveries';
import { OperatorsShortnames } from 'utils/OperatorsShortnames';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';

import BikeAvailability from './BikeAvailability';
import NightTrainOffers from '../atoms/NightTrainOffers';

const AppJourneyLegs = ({ journeyLegs }: { journeyLegs: Leg[] }) => {
  const t = useTranslations('Tickets');

  return (
    <div className="font-light">
      {journeyLegs?.map((leg, index) => (
        <LegDisplay
          key={index}
          leg={leg}
          nextLeg={journeyLegs[index + 1]}
          isLast={index === journeyLegs.length - 1}
          t={t}
        />
      ))}
    </div>
  );
};
export default AppJourneyLegs;

const LegDisplay = ({
  leg,
  nextLeg,
  isLast,
  t,
}: {
  leg: Leg;
  nextLeg: Leg;
  isLast: boolean;
  t: any;
}) => {
  // Google & SNCF results are missing the type field (24/01/2024)
  switch (leg.type) {
    case 'TRAIN_TRAVEL':
      return <DefaultLeg leg={leg} last={true} nextLeg={nextLeg} />;
    case 'PLATFORM_CHANGE':
      return <PlatformChangeLeg leg={leg} t={t} />;
    case 'STATION_CHANGE_PUBLIC_TRANSPORT':
      return <StationChangePublicTransportLeg leg={leg} t={t} />;
    case 'STATION_CHANGE_WALK':
      return <StationChangeWalkLeg leg={leg} t={t} />;
    default:
      return <DefaultLeg leg={leg} last={isLast} nextLeg={nextLeg} />;
  }
};

const ChangeLeg = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-xs text-slate-400 font-light ml-10">{children}</div>;
};

const PlatformChangeLeg = ({ leg, t }: { leg: Leg; t: any }) => {
  return (
    <div className="mb-3">
      <ChangeLeg>
        <div className="flex items-center">
          <ShuffleIcon className="inline-block mr-2" size={16} />
          <span>{getHoursFromDuration(leg.duration)} min</span>
          <span>&nbsp;{t('connexions.platformChange')}</span>
        </div>
      </ChangeLeg>

      {leg.hint && leg.hint.type == 'delayAlert' && (
        <Accordion type="single" collapsible>
          <AccordionItem
            value="1"
            className="rounded-xl bg-orange/20 px-4 border-none mt-1"
          >
            <AccordionTrigger className="py-2 text-orange text-sm">
              {t('hint.delayAlert.title')}
            </AccordionTrigger>
            <AccordionContent>{leg.hint.message}</AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

const StationChangePublicTransportLeg = ({ leg, t }: { leg: Leg; t: any }) => {
  return (
    <ChangeLeg>
      <div className="flex items-center mb-3">
        <TrainIcon className="inline-block mr-2" size={16} />
        <span>{getHoursFromDuration(leg.duration)} min</span>
        <span>&nbsp;{t('connexions.stationChangeTransport')}</span>
      </div>
    </ChangeLeg>
  );
};

const StationChangeWalkLeg = ({ leg, t }: { leg: Leg; t: any }) => {
  return (
    <ChangeLeg>
      <div className="flex items-center mb-3">
        <Footprints className="inline-block mr-2" size={16} />
        <span>{getHoursFromDuration(leg.duration)} min</span>
        <span>&nbsp;{t('connexions.stationChangeWalk')}</span>
      </div>
    </ChangeLeg>
  );
};
export const getTrainLineInfo = (_leg: Leg) => {
  const operatorName = OperatorsShortnames(_leg.operator.name);
  const lineName = _leg.line?.name ?? '';
  const lineReference = _leg.line?.reference ?? '';
  return operatorName !== '' && operatorName !== lineName
    ? operatorName + ' - ' + lineName + ' ' + lineReference
    : lineName + ' ' + lineReference;
};
const DefaultLeg = ({
  leg,
  nextLeg,
  last,
}: {
  leg: Leg;
  nextLeg: Leg;
  last: boolean;
}) => {
  const locale = useLocale();
  const centeredDot = '\u00B7';
  const t = useTranslations('Tickets');

  return (
    <div className="relative mb-3 flex flex-col">
      <div className="relative">
        <div className="absolute top-2 bottom-2 left-12 border-r-[3px] -translate-x-1/2"></div>
        <div className="flex items-start">
          <div className="relative mr-4 block w-11 pr-3 text-sm font-semibold">
            <div className="absolute top-1 -right-2 h-2 w-2 rounded-full border-[1px] bg-white"></div>
            {formatLinkDate(leg.departure, locale)}
          </div>
          <div className="pr-4 text-sm font-semibold">
            <span>{leg.origin.name}</span>
          </div>
        </div>

        <div className="relative -ml-10 flex h-auto w-auto flex-row items-center text-slate-400 font-light">
          <Image
            className="flex"
            src={Line(leg)}
            alt={leg.mode}
            height={28}
            width={70}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <div className="flex flex-row items-center ml-5 pl-2.5">
            <div className="flex flex-row text-xs">
              <ClockIcon className="mr-1 h-[14px] w-[14px] self-center" />
              {getDiffHoursFromDates(leg.arrival, leg.departure)}
            </div>
            <p className="text-xl mx-1">{centeredDot}</p>
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize">
              {getTrainLineInfo(leg)}
            </p>
          </div>

          <BikeAvailability leg={leg} />
        </div>
        <NightTrainOffers leg={leg} keyForValues="journeyLegs" />

        <div className="mt-3 flex items-end">
          <div className="relative mr-4 w-11 pr-3 text-sm font-semibold">
            <div className="absolute top-1 -right-2 h-2 w-2 rounded-full border-[1px] bg-gray-400"></div>
            {formatLinkDate(leg.arrival, locale)}
          </div>
          <div className="pr-4 text-sm font-semibold">
            <span>{leg.destination.name}</span>
          </div>
        </div>
      </div>

      {!last && (
        <ChangeLeg>
          <div className="flex items-center pt-2">
            <ShuffleIcon className="inline-block mr-2" size={15} />
            {getDiffHoursFromDates(nextLeg.departure, leg.arrival)}{' '}
            {t('connexions.default')} {leg.type}
          </div>
        </ChangeLeg>
      )}
    </div>
  );
};
